<template>
    <div>        
        <s-crud
            title="Armado de Estático"
            ref="gridStatico"
            excel
            edit
            add 
            :filter="filter"
            add-no-function    
            remove
            sortable
            :config="config"            
            @rowSelected="rowSelected($event)"            
            @add="add()"
            @edit="edit()"
            @save="saveBags($event)"
            searchInput>
            
            <template v-slot:FasStatus="{ row }">
				<v-chip x-small=""  :color="row.FasStatus == 1 ? 'success': 'error'" >
					{{ row.FasStatus == 1 ? 'Acondicionado': 'Empaque' }}
				</v-chip>
        	</template>

            <template v-slot:ImgQr="{ row }">
				<v-btn x-small icon
                color="info"
                @click="ImpQr(row)"
                 fab>
                    <v-icon color="black">
                        mdi-qrcode
                    </v-icon>                    
                </v-btn>
        	</template>

            <template v-slot:Options="{ row }">
                <v-btn :disabled="row.FasStatus != 1" x-small icon
                color="light-green darken-1"
                @click="ModelMoveToContainers(row)"
                 fab>
                    <v-icon color="#5edf87">
                        fa-light fa-people-carry
                    </v-icon>                    
                </v-btn>
            </template>
            <template v-slot:filter>
                <v-container>
                    <v-row justify="center" class="s-col-form">
                        <v-col>
                            <s-select   
                                :items="LocationCar"
                                item-value="FasStatus"
                                item-text="FasLocation"
                                v-model="filter.FasStatus">
                            </s-select>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="s-col-form">
                        <v-col>
                            <s-date 
                                label="Fecha"
                                v-model="filter.DateBegin"></s-date>
                        </v-col>
                        <v-col>
                            <s-date 
                                label="Fecha Fin"
                                v-model="filter.DateEnd"></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
        
        <v-dialog max-width="1200" v-model="openDialog" v-if="openDialog" persistent >
            <assembly-edit  @save="save()"  @closestatic="closeedit()" @RefreshCars="RefreshCars()" :selected="selected" v-model="FasID" >
            </assembly-edit>
        </v-dialog>
        <v-dialog max-width="600" v-model="dialogMoveContainer" persistent>
            <move-car @save="save()" @closedialog="close()" :selected="selected" >
            </move-car>
        </v-dialog>

        <v-row justify="center" cols="6" style="display: none">
            <v-col cols="6" v-for="item in selected">
                <qr-code
                    :text="
                        item.FasID+
							'' 
							
                    "
                    error-level="H"
                    hidden
                >
                </qr-code
                ><br />
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import _sAssemblyStatic from '@/services/FrozenProduction/FrzCtnAssemblyStaticService.js'
    import AssemblyEdit from '@/views/FrozenProduction/FrzAssemblyStatic/FrzCtnAssemblyStaticEdit'
    import MoveCar from '@/views/FrozenProduction/FrzAssemblyStatic/FrzCtnMoveCarContainer';
    import SDate from '../../../components/Utils/SDate.vue';

    import qrCode from "vue-qr-generator";
    
    export default{
        components: { AssemblyEdit, MoveCar, qrCode },     
        data() {
            return { 
                LocationCar: [
                    { FasStatus: 1, FasLocation: 'ACONDICIONADO'},
                    { FasStatus: 2, FasLocation: 'MADURACION'}
                ],
                selected: [],                
                openDialog: false,                
                dialogMoveContainer: false,                       
                filter:{
                    FasStatus: 1,
                },
                config:{
                    service: _sAssemblyStatic,
                    model:{
                        FasID: "ID",
                        FasStatus: "FasStatus",
                        Options: "Options",
                        ImgQr:"ImgQr"
                    },
                    headers:[
                        {text: "Qr", value: "ImgQr",  align: "center", width: "10%"},
                        {text: "Enviar a Precamara", value: "Options",  align: "center", width: "10%"},
                        {text: "Cultivo", value: "TypeCultiveName",  align: "center", width: "10%", sortable: true},
                        {text: "Variedad", value: "VrtDescription",  align: "center", width: "10%", sortable: true},
                        // {text: "ID", value: "FasID",  align: "center", width: "10%", sortable: true},
                        // {text: "N° de coche", value: "FasCarNumber",  align: "center", width: "10%", sortable: true},
                        {text: "Cliente", value: "CumNombre",  align: "center", width: "10%", sortable: true},
                        {text: "Marca", value: "CbdBrans",  align: "center", width: "10%", sortable: true},
                        {text: "Linea", value: "LncDescription",  align: "center", width: "10%", sortable: true},
                        {text: "Tipo de corte", value: "TypeCutName",  align: "center", width: "10%"},
                        // {text: "Tipo de bandeja", value: "NumberBags",  align: "center", width: "80"},
                        {text: "Cantidad de mallas", value: "NumberBags",  align: "center", width: "30"},
                        {text: "Peso prom. malla", value: "WeightAverageBags",  align: "center", width: "100"},
                        // {text: "Peso neto de mallas", value: "WeightNetBags",  align: "center", width: "100"},
                        {text: "Fecha de Armado", value: "DateCreate",  align: "center", width: "30"},
                        {text: "Tipo de Carrito", value: "TypeCarConditionedName",  align: "center", width: "30"},
                        {text: "Estado", value: "FasStatus",  align: "center", width: "30"},
                    ]
                },            
                FasID: 0,
                executeQR: false,
            }
        },
        methods: {
            add()
            {                       
                this.selected = [];  
                this.FasID = 0;
                this.openDialog = true;

            },
            edit(){  
                this.FasID = this.selected.length > 0 ? this.selected[0].FasID : 0;             
                this.openDialog = true;
            },
            rowSelected(item)
            {                
                this.selected = [];                
                this.selected = item;     
                this.FasID = this.selected.length > 0 ? this.selected[0].FasID : 0;                                                                              
            },            
            save()
            {                
                this.$refs.gridStatico.refresh();
            },
            ModelMoveToContainers(item){                   
                this.selected = [];
                this.selected = item;     
                this.dialogMoveContainer = true;                     
            },
            close()
            {      
                this.FasID = 0;
                this.dialogMoveContainer = false;   
            }, 
            closeedit()
            {         
                this.FasID = 0; 
                this.selected = [];      
                this.openDialog = false;                
            },
            RefreshCars(){
                this.$refs.gridStatico.refresh();
            },
            ImpQr(item){
                
                _sAssemblyStatic.GetQr({FasID : item.FasID}, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {
                        this.selected = resp.data;
                        this.printQR();
                        
                    }
                });
            },
            printQR() {           
                    var imgAll = document.querySelector("img");
                    
                    var Pagelink = "about:blank";
                    var pwa = window.open(Pagelink, "_new");
                    pwa.document.open();
                    pwa.document.write(
                        "<html><head><scri" +
                            "pt>function step1(){\n" +
                            "setTimeout('step2()', 10);}\n" +
                            "function step2(){window.print();window.close()}\n" +
                            "</scri" +
                            "pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
                            " @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
                            "<div class='col col-12 justify-center'>",
                    );
                        pwa.document.write(
                            "<div class='col col-6' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
                                imgAll.currentSrc +
                                "' />",
                        );
                        pwa.document.write("<b style='font-size: 20px; z-index: 2;transform: rotateZ(0deg);margin-top: 40px;position: absolute;width: 133px;border-radius: 20px;'> ID : " +
                            this.selected[0].FasID
                            +"</b>"); 


                
                    pwa.document.write("</div></body></html>");
                    pwa.document.close();
               
				
			},           
        },
        created(){
            
        },
        computed: {},
    };
</script>